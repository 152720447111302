import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

  state:{
    products:[],
    cart:[],
    totalCart:[],
    amountCart: 0,
    amountTransport: 0,
    user: null,
    urlImage: "",
    userAddress:[]
  },
//--------------------------------------------
  getters:{
    getProducts(state){
      return state.products;
    },

    getInCart(state){
      return state.cart;
    },

    getTransport(state){
      return state.amountTransport
    },

    getUrlImage(state){
      return state.urlImage
    },

    getUserAddress(state){
      return state.userAddress
    },

    getUser(state){
      return state.user
    }

  },
//---------------------------------------------
  mutations: {
    // FETCH_USER(state, user){
    //   state.user = user
    // },

    FETCH_USER_ADDRESS(state, userAddress){
      state.userAddress = userAddress
    },

    FETCH_URL_IMAGE(state, urlImage){
      state.urlImage = urlImage;
    },

    FETCH_PRODUCTS(state, products){
      state.products = products;
    },

    INSERT_IN_CART(state, prod){

      state.cart.push(prod);

      for(let i=0; i<(state.cart.length)-1; i++)
      {
        //verification de doublon
        if(prod.id == state.cart[i].id)
        {
          // suppression des montants dans "cart" et "totalCart"
          state.cart.splice(i, 1);
          state.totalCart.splice(i, 1);
          
        }
      }
      // calcul du montant du panier:
      state.totalCart.push(prod.quantity*prod.price) 
      const reducer = (accumulator, currentValue) => accumulator + currentValue;  
      state.amountCart = (state.totalCart.reduce(reducer,0)).toFixed(2);
      //localStorage.removeItem('cart');

      // // sauvegarde des produits en sessionStorage
      // console.log("Nom du produit inséré dans le panier: "+prod.name);
      // console.log("Longueur du panier dans le store: "+state.cart.length)
      // for(let i=0; i<(state.cart.length); i++){
      //   sessionStorage.setItem('idPan'+[i], state.cart[i].name)
        
      // }
      // //affichage des produits en sessionStorage
      // console.log("Longueur du panier dans sessionStorage: "+sessionStorage.length);
      // console.log("Le panier contient: ");
      // for(let i=0; i<(sessionStorage.length); i++){
      // console.log(sessionStorage.getItem('idPan'+[i]));
      // }

      //sauvegarde d'un objet panier en sessionStorage
      var cartObjectJson = JSON.stringify(state.cart);
      console.log(cartObjectJson);
      sessionStorage.setItem('Panier', cartObjectJson)

      //affichage du panier stocké en sessionStorage
      var cartStorageJson = sessionStorage.getItem('Panier');// Objet Json
      var cartStorage = JSON.parse(cartStorageJson); //Objet
      console.log(cartStorage); //Affichage dans la console
      console.log(cartStorage.length); //Affichage dans la console
      console.log(state.cart);
      
    },
    PERSIST_CART(state){
      var cartStorageJson = sessionStorage.getItem('Panier');// Objet Json
      var cartStorage = JSON.parse(cartStorageJson); //Objet
      state.cart = cartStorage;
      for(let i=0; i<state.cart.length; i++){
        // calcul du montant du panier:
      state.totalCart.push(state.cart[i].quantity*state.cart[i].price) 
      const reducer = (accumulator, currentValue) => accumulator + currentValue;  
      state.amountCart = (state.totalCart.reduce(reducer,0)).toFixed(2);
      }
    },
  
    CLEAN_CART(state){
      state.cart = []
      sessionStorage.removeItem('cart')
    },

    NEW_USER(state, profile){
      state.user = profile
    },

    NEW_TRANSPORT(state, typeTransport){
        state.amountTransport = typeTransport
      },

  },
//---------------------------------------------
  actions: {
    // async fetchUser(context, profile){
    //   if(profile!=null){
    //     await fetch(process.env.VUE_APP_URL_REQ+'/api/users/'+ profile,
    //     {
    //         method: 'GET',
    //         headers:{
    //             'Authorization' : 'Bearer '+localStorage.getItem('tok')
    //         }
    //     })
    //     .then(Response=>{
    //       return Response.json()
    //     })
    //     .then(json=>{
    //         console.log(json)
    //         console.log(json['email'])
    //         context.commit('FETCH_USER', json)
    //     })
    //   } else {
    //     context.commit('FETCH_USER', null)
    //   }
    // },

    async fetchUserAddress(context){
      try 
      {
        await fetch(process.env.VUE_APP_URL_REQ+'/api/user_adresses',{
        method:'GET',
        headers:{
            'Authorization' : 'Bearer '+localStorage.getItem('tok')
        }
      })
      .then(Response=>{
          return Response.json()
      })
      .then(json=>{
        console.log(json)
        let i;
        let totalItems = json['hydra:totalItems'];
        console.log(totalItems);
        let adresses = json['hydra:member'];
        console.log('testStore: '+ adresses)
        for(i=0; i<totalItems; i++){
          if(adresses[i].userId === '/api/users/'+localStorage.getItem('id')){
            console.log("storeTest: "+adresses[i])
          context.commit('FETCH_USER_ADDRESS', adresses[i])
          }
      }  
        })
    }
    catch(error){
      console.log(error)
    }
    },

    async fetchUrlImage(context, ean){
      await fetch(process.env.VUE_APP_URL_REQ+'/api/image/'+ean)
        .then((Response)=>{
            return Response.json() 
        })
        .then((json)=>{
            context.commit("FETCH_URL_IMAGE", json)
        })
        .catch((error)=>{
        console.log(error)})
    },

    async fetchProducts(context){
      await fetch(process.env.VUE_APP_URL_REQ+'/api/products/actifs')
        .then((response)=>
            response.json()
        )
        .then((json)=>
            
            context.commit("FETCH_PRODUCTS", json) 
        )
    },

    insertInCart(context, prod){
      context.commit('INSERT_IN_CART', prod)
    },
    
    persistCart(context, cart){
      context.commit('PERSIST_CART', cart)
    },

    cleanCart(context){
      context.commit('CLEAN_CART')
    },

    newUser(context, profile){
      context.commit('NEW_USER', profile)
    },

    newTransport(context, typeTransport){
      context.commit('NEW_TRANSPORT', typeTransport)
    },

  },
//---------------------------------------------
  modules: {
    
  }
})
