<template>
<div>
    <account-user></account-user>
</div>
</template>
<script>
import AccountUser from '../components/security/AccountUser.vue';



export default {
    name: 'Profile',
 
    components:{
        AccountUser
    }
}
</script>

