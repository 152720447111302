<template>
    <div>
        <!---<FormAdress formType='livraison' />--->
        <div v-show="!user" class="profile">
            <LoginComponent />
            <RegisterComponent />
        </div>

        <div v-show="user">
            <div v-if="userAddress">
                 <p>Adresses de livraison et facturation identiques</p>
                    {{ userAddress.adress }}
                    {{ userAddress.city }}
                    {{ userAddress.zipCode }}
                    {{ userAddress.country }}
                <button type="button" @click="handleSubmit" class="btn btn-outline-success">Valider</button>
                <div>
                    <span>Je souhaite une livraison à une adresse différente?  </span>
                    <button type="button" @click="adressInvoice" class="btn btn-outline-success">Valider</button>
                </div>
            </div>
            <div v-else>
                Vous n'avez pas indiqué d'adresse dans votre compte: 
                <button type="button" @click="createAdress" class="btn btn-outline-success">Mon adresse</button>
            </div>
        </div>
        
       
    </div>
</template>
<script>
import LoginComponent from '@/components/security/Login.vue'
import RegisterComponent from '@/components/security/Register.vue'
import { mapState } from 'vuex'
//import FormAdress from "../elements/FormAdress"
export default {
    Name:'DeliveryAdress',
    components: {
        //FormAdress,
        LoginComponent,
        RegisterComponent
    },
    computed:{
        ...mapState(['user']),
        ...mapState(['userAddress'])
        
    },
    data(){
        return{
            adressExist: true,
            otherAdress: false,
            transportOk: false,
        }
    },
      async created(){
      this.$store.dispatch('fetchUserAddress', localStorage.getItem('id'))
    },
 
    methods:{
    handleSubmit(){
            this.$emit('adressValid', this.transportOk);
    },

    adressInvoice(){
        this.otherAdress = !this.otherAdress
    },

    createAdress(){
        this.$router.push('/accountUser')
    }
    }

}
</script>