<template>
    <div>
        <footer class="bg-light text-center text-lg-start">
            <!-- Grid container -->
            <div class="container p-4 pb-0">
                
            </div>
            <!-- Grid container -->

            <!-- Copyright -->
            <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
                © {{ year }} Copyright:
                <a class="text-dark" href="#" style="text-decoration: none">Shop-Link.com</a>
            </div>
            <!-- Copyright -->
        </footer>
    </div>
</template>
<script>
export default {
    Name: 'Footer',
    computed:{
        year(){
            let date = new Date();
            return date.getFullYear()
        }
    }
}
</script>