<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <h3>Connexion</h3>
            <div class="form-group">
                <input type="email" class="form-control" placeholder="Enter email" v-model="user.email">
            </div>
            <br>
            <div class="form-group">
                <input type="password" class="form-control" placeholder="Password" v-model="user.password">
            </div>
            <br>
            
            <button type="submit" class="btn btn-outline-primary">Connexion</button>
        </form>
        {{ messageError }}
    </div>
</template>
<script>

export default {
    name:'Login',
    data(){
        return {
            messageError:"",
            user:{
                email:"",
                password:""
            }
        }
    },
    methods:{
        async handleSubmit(){
           
            await fetch(process.env.VUE_APP_URL_REQ+'/api/login_check', {
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body: JSON.stringify(this.user)
            })
            .then(response=>response.json())
            .then(result=> {
                console.log(result);
                localStorage.setItem('tok', result.token)
                localStorage.setItem('id', result.data.id)
                localStorage.setItem('name', result.data.name)
                localStorage.setItem('email', result.data.email)
                this.$store.dispatch('newUser', result.data.name)
                })
            .catch(error=>{
                console.log(error)
                this.messageError ="Erreur de saisie mot de passe ou identifiant"
                return this.messageError
                })
            this.$store.dispatch('fetchUserAddress', localStorage.getItem('id'))
        }
    },
}
</script>
