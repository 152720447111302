<template>
    <div>
        <h1>Merci de retouner sur la page d'accueil.</h1>
    </div>
</template>

<script>
export default {
    name: 'Unknown',
}
</script>