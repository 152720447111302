import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue';
import Order from '../views/Order.vue'
import PaymentResult from '../views/PaymentResult.vue'
import Payment from '../components/orders/Payment.vue'
import Unknown from '../views/Unknown.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/exposition',
    name: 'Exposition',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Exposition.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/paymentResult',
    name: 'PaymentResult',
    component: PaymentResult
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '*',
    name: 'unknown',
    component: Unknown
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
