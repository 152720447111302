<template>
    <div class="cart">

        <div  
        v-if="cart.length==1"
        >
        <button type="submit" @click="seeInCart()" class="btn btn-outline-success"><b-icon-cart4></b-icon-cart4> {{ cart.length }} ligne</button>
        </div>

        <div
        v-else
        >
        <button type="submit" @click="seeInCart()" class="btn btn-outline-success"><b-icon-cart4></b-icon-cart4> {{ cart.length }} lignes</button>
        </div>

        <div
            v-if="showCart"
        >
        <br>
        <table class="table" style="background-color: light">
            <thead>
                <tr>
                    <th>Quantité</th>
                    <th>Nom</th>
                    <th>Prix Unitaire TTC</th>
                    <th>Prix Total TTC</th>
                    <th>Supprimer</th>
                </tr>
            </thead>
            <tbody
                    class="content"
                    v-for="prod in cart"
                    :key="prod.id"
                    >
                <tr>
                    <td scope="row">{{ prod.quantity }}</td>
                    <td>{{ prod.name }}</td>
                    <td>{{ prod.price }} €</td>
                    <td></td>
                    <td @click="trashCommand(prod.id, prod.name, prod.price, 0)"><b-icon-trash id="trashCommand"></b-icon-trash></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td scope="row"><strong>Total Panier:</strong></td>
                    <td></td>
                    <td></td>
                    <td><strong>{{amountCart}} €</strong></td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><button type="submit" v-if="amountCart>0" class="btn btn-light btn-sm" @click="handleClick">Commander</button></td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { BIconCart4, BIconTrash } from 'bootstrap-vue';
export default {
    Name: "Cart",
    components:{
        BIconCart4,
        BIconTrash
    },
    data(){
        return {
            showCart: false,
        }
    },
    computed:{
        ...mapState(["cart", "amountCart"]),
        ...mapGetters(["getInCart"]),
    },
    
    methods:{
        seeInCart(){
            this.showCart = !this.showCart
        },

        handleClick(){
            this.showCart = !this.showCart
            this.$router.push('/order')
        },

        trashCommand(id, name, price, quantity){
            // comme pour l'ajout d'un produit mais avec une quantité nulle
            let prod = { id, name, price, quantity };
            this.$store.dispatch('insertInCart', prod)
        }

    }
}
</script>
<style lang="scss" scoped>

    table{
        background-color: rgb(170, 236, 206);
        border-radius: 10px;
        text-align: start;
    }

    #trashCommand {
        cursor: pointer;
    }
</style>