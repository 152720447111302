<template>
    <div>
        <form
            v-if="!registeredUser"
        >
            <h3>Inscription</h3>
            <div class="form-group">
                <label for="InputName">Votre nom ou pseudonyme</label>
                <input type="text" class="form-control" id="InputName" placeholder="Nom ou pseudonyme" v-model="user.name" required>
            </div>
            <br>
            <div class="form-group">
                <label for="InputEmail1">Adresse Email</label>
                <input type="email" class="form-control" id="InputEmail1" aria-describedby="emailHelp" placeholder="Email" v-model="user.email">
                <small id="emailHelp" class="form-text text-muted">Vos données resteront confidentielles.</small>
            </div>
            <br>
            <div class="form-group">
                <label for="InputPassword">Mot de passe</label>
                <input type="password" class="form-control" id="InputPassword" placeholder="Mot de passe" v-model="user.password">
            </div>
            <br>
            <div class="form-group">
                <label for="InputPassword2">Confirmation du mot de passe</label>
                <input type="password" class="form-control" id="InputPassword2" placeholder="Mot de passe" v-model="user.password2">
                <div 
                    class="verif"
                    v-if="verif"
                    style="background-color: pink"
                >Verifiez votre mot de passe !</div>
            </div>
            <br>
            <button type="submit" class="btn btn-outline-primary" @click="register">Inscription</button>
        </form>
        <div 
        v-else
        style="width: 40%; background-color: green; color: white; margin-top: 30px; border-radius: 10px"
        >
            <p>
                Merci pour votre inscription, vous pouvez vous connecter.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name:"Register",
    data(){
        return{
            verif: false,
            user:{
                name:"",
                email:"",
                roles:[],
                password:"",
                is_verified: false
            },
            registeredUser: false
        }
    },
    methods:{
        async register(e){
            e.preventDefault();
            
            console.log('Go');
            if(this.user.password === this.user.password2){
                console.log('hello');
                console.log(JSON.stringify(this.user));
                await fetch(process.env.VUE_APP_URL_REQ+'/api/register', {
                    method: 'POST',
                    headers:{
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.user),
                })
                .then(response=>response.json())
                .then(result=>console.log(result))
                .catch(error=>error);
                this.registeredUser=true
            } else {
                alert(this.user.password + this.user.password2)
                this.verif = !this.verif;
            }
        }
    }
}
</script>