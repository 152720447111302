<template>
  <div class="home">
    
    <carousel-component />
    <h3>Nouveautés</h3><br>
    <h3>Promotions</h3><br>
  </div>
</template>

<script>
// @ is an alias to /src

import CarouselComponent from '../components/CarouselComponent.vue'

export default {
  name: 'Home',
  components: {
   
    CarouselComponent
  }
}
</script>
