<template>
   <div>
        <div v-show="!user" class="profile">
            <LoginComponent />
            <RegisterComponent />
        </div>
        <div v-show="user" class="profile">
            <div>
                <show-adress />
                <div class="btn btn-outline-success" @click="myOrders">
                    Voir les commandes précédentes
                </div>
                    <div v-if="showMyOrders">
                        <h3>Historique:</h3>
                        <div
                        v-for="order in orders"
                        :key="order.id"
                        >
                            <div class="btn btn-sm-success">    
                                <a :href="linkUrl+'/api/invoices'+(order.urlInvoiceFile)"> {{ order.urlInvoiceFile}}</a>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LoginComponent from '@/components/security/Login.vue';
import RegisterComponent from '@/components/security/Register.vue';
import ShowAdress from '@/components/elements/ShowAdress.vue';
export default {
    Name: "AccountUser",
    components: { 
        ShowAdress,
        LoginComponent,
        RegisterComponent,
         },
    data(){
        return{
            linkUrl: process.env.VUE_APP_URL_REQ,//declaration de linkUrl pour pouvoir le mettre dans le lien au dessus
            userProfile:{
                name:"",
                email:""
            },
            orders:[],
            showMyOrders: false
        }
    },
    computed:{
        ...mapState(['user']),
        ...mapState(['userAddress'])
    },
    async created(){
      this.$store.dispatch('fetchUserAddress', localStorage.getItem('id'))
    },
    methods:{
        
        async myOrders(){
            this.showMyOrders = !this.showMyOrders;
            this.showBillingAdress = false;
            console.log(process.env.VUE_APP_URL_REQ);
            this.orders = await fetch(process.env.VUE_APP_URL_REQ+'/api/getInvoice/'+localStorage.getItem('id'),{
                method:'GET',
                headers:{
                    'Authorization': 'Bearer '+localStorage.getItem('tok')
                }
            })
            .then(response=>{
                return response.json()
            })
            .then(json=>{
                let data = json.invoices;
                console.log(data);
                let dataJson = JSON.parse(data);
                console.log(dataJson);
                console.log(dataJson.length);
                return dataJson
            })

        },
        
    }
}
</script>

<style lang="scss">
    
</style>