<template>
    <div>
        <div>
            {{ result }}
        </div>
    </div>
</template>
<script>
export default {
    Name:"PaymentResult",
    computed: {
        result(){
            return this.$route.params.result
        }
    }
   
}
</script>