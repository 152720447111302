<template>
    <div>
        <!---<RecapCommandes @orderValidEvent="continue(event)" />--->
        <RecapCommandes v-show="!orderOk" @orderValidEvent="nextPath($event)" />
        <Transports v-show="transportOk" @deliveryOk="validPath($event)"/>
        <!---<PaymentComponent v-show="userOk" />--->
    </div>
</template>
<script>
import { mapState } from 'vuex'
import RecapCommandes from '@/components/orders/RecapCommandes.vue'
import Transports from '@/components/orders/Transports.vue'
//mport PaymentComponent from '@/components/orders/Payment.vue'
export default {
    Name:"Order",
    data(){
        return {
            orderOk: false,
            transportOk: false,
            adressOk: false,
            userOk: false

        }
    },
    computed:{
        ...mapState(['user', 'userAddress', 'amountTransport'])
    },
    components:{
        RecapCommandes,
        Transports,
        //PaymentComponent,

    },
      async created(){
      this.$store.dispatch('fetchUserAddress', localStorage.getItem('id'))
    },
    methods:{
        nextPath(validation){
            console.log("parent: "+validation);
            this.transportOk = validation;
            this.orderOk = true   
        },
        validPath(validation){
            console.log("parent: "+validation);
            console.log("transport: "+this.$store.state.amountTransport);
            this.adressOk = validation;
            this.transportOk= false
            this.userOk = true
            this.$router.push('payment')
        }
    }
}
</script>