<template>
    <div>
        <h3>Récapitulatif de ma commande:</h3>
        <table class="table" style="background-color: light">
            <thead>
                <tr>
                    <th>Quantité</th>
                    <th>Nom</th>
                    <th>Prix Unitaire TTC</th>
                    <th>Prix Total TTC</th>
                </tr>
            </thead>
            <tbody
                    class="content"
                    v-for="prod in cart"
                    :key="prod.id"
                    >
                <tr>
                    <td scope="row">{{ prod.quantity }}</td>
                    <td>{{ prod.name }}</td>
                    <td>{{ prod.price }} €</td>
                    <td></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td scope="row"><strong>Total Panier:</strong></td>
                    <td></td>
                    <td></td>
                    <td><strong>{{amountCart}} €</strong></td>
                </tr>
            </tfoot>
        </table>

        <button type="submit" class="btn btn-outline-success" @click="orderValid">Valider ma commande</button>
    </div>
</template>
<script>
import {mapState, mapGetters} from 'vuex';
export default {
    Name:"RecapCommandes",
    data(){
        return {
            orderOk: true,
            }
        },
    computed:{
        ...mapState(['cart', 'amountCart', 'userAddress']),
        ...mapGetters(['getInCart'])
    },
      async created(){
      this.$store.dispatch('fetchUserAddress', localStorage.getItem('id'))
    },
    methods:{
        orderValid(){
            console.log('validation de commande');
            this.$emit('orderValidEvent', this.orderOk)
        }
    }
}
</script>