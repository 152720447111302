<template>
  <div id="app">
    <NavComponent id="nav" />
    <CartComponent id="cart" />
    <transition 
      name="fade"
      mode="out-in"
    >
      <router-view/>
    </transition>
    <FooterComponent />
  </div>
</template>

<script>
import NavComponent from '@/components/Nav.vue';
import CartComponent from '@/components/Cart.vue';
import FooterComponent from '@/components/Footer.vue';
export default {
  name:"App",
  components:{
    NavComponent,
    CartComponent,
    FooterComponent
  },
  created() {
    //this.$store.dispatch('insertInCart', localStorage.getItem('cart'));
    if(localStorage.getItem('tok'))
    {
      this.$store.dispatch('newUser', localStorage.getItem('name'))   
    }
    console.log(sessionStorage['Panier']);
    console.log(this.$store.state.cart.length);
    if(sessionStorage['Panier'] && this.$store.state.cart.length === 0){
      console.log("il y a une différence")
      this.$store.dispatch('persistCart')
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Verdana, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-left: 5%;
  margin-right: 5%;
}

#nav {
 padding: 5px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#cart{
  text-align: end;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0
}

</style>
