<template>
<div>
    <nav class="navbar navbar-light bg-light">
        <a class="navbar-brand" href="#">
            <img src="../assets/logo_drugs.png" width="50" height="50" alt="">
            Shop-Link
        </a>
        <router-link  class="nav-link" to="/">Accueil <b-icon-house></b-icon-house></router-link>
        <router-link class="nav-link" to="/exposition">Produits<b-icon-list></b-icon-list></router-link>
        <div
            v-if="!user"
        >
            <router-link  class="nav-link" to="/profile">Inscription <b-icon-person-plus></b-icon-person-plus> / Connexion <b-icon-door-closed></b-icon-door-closed></router-link>
        </div>
        <div
            v-else
        >
            <a href="javascript: void(0)" @click="handleClick" class="nav-link">Déconnexion <b-icon-person-x></b-icon-person-x></a>
            <div class="nav-link" id="nameUser"  @click="accountUser">Bonjour, {{ user }}</div>
        </div>
    </nav>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { BIconHouse, BIconList, BIconDoorClosed, BIconPersonPlus, BIconPersonX } from 'bootstrap-vue';
export default {
    name: 'Nav',
    components:{
        BIconHouse,
        BIconList,
        BIconDoorClosed,
        BIconPersonPlus,
        BIconPersonX
    },
    computed:{
        ...mapState(['user']),
        ...mapGetters(['getUser'])
    },
    methods:{
        handleClick(){
            localStorage.removeItem('tok');
            localStorage.removeItem('name');
            localStorage.removeItem('email');
            localStorage.removeItem('id');
            this.$store.dispatch('newUser', null);
            this.$router.push('/profile');
        },
        accountUser(){
            this.$router.push('/profile')
        }

    }
}
</script>
<style lang="scss">
    #nameUser{
        cursor: pointer;
    }
</style>
