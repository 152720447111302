<template>
    <div id="carousel" style="text">
            <b-carousel
                id="carousel-fade"
                background="#ababab"
                style="text-shadow: 0px 0px 2px black"
                fade
                
                
                indicators
                img-width=100%
                img-height=100%
            >
            
                <b-carousel-slide 
                v-for="product in productsLimited"
                :key= product.id
                :caption="product.name"
                :img-src="product.imagePath"
                

                ></b-carousel-slide>
            </b-carousel>
        </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { BCarousel, BCarouselSlide } from 'bootstrap-vue';

export default {
    name: "Carouselcomponent",
    components:{
        BCarousel,
        BCarouselSlide
    },
    computed:{
        ...mapState(['products']),
        ...mapActions(['fetchProducts']),
        productsLimited() {
        return this.products.slice(0, 4)
    }
    },
    created(){
        this.$store.dispatch('fetchProducts')    
    }
}
</script>

<style scoped>


</style>