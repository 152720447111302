<template>
  <div>
   <!-- Display a payment form -->
    <form id="payment-form">
      <h3>Montant à payer: {{ amount }}€</h3>
      <div id="card-element"><!--Stripe.js injects the Card Element--></div>
      <button id="submit">
        <div class="spinner hidden" id="spinner"></div>
        <span id="button-text">Pay now</span>
      </button>
      <LoadingComponent v-show="loading" />
      <p id="card-error" role="alert"></p>
      <p class="result-message hidden">
        Payment succeeded, see the result in your
        <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.
      </p>
    </form>
  </div>
</template>

<script>
import { mapState} from 'vuex';
import LoadingComponent from '@/components/Loading.vue';

export default {
  data () {
    this.publishableKey = process.env.VUE_APP_KEY_STRIPE
    return {
      loading: false,
      showCart: false,
      amount: '',
      invoiceTab:{
                idUser:"",
                billingNumber:"",
                userInvoice:"",
                productCart:"",
                amountCart:"",
                transportCost:"",
                urlInvoice:"",
            }
    };
  },
  components:{
      LoadingComponent
  },
  computed:{
      ...mapState(['amountCart', 'amountTransport', 'cart']),
  },
  async beforeMount() {
    let stripe = this.$stripe;
      let router = this.$router;
      let store = this.$store;
      let invoiceTab = this.invoiceTab;
      console.log("montant panier: "+ this.$store.state.amountCart);
      console.log("montant transport: "+ this.$store.state.amountTransport);
      this.amount = (Number(this.$store.state.amountCart) + Number(this.$store.state.amountTransport)).toFixed(2)
      
      await fetch(process.env.VUE_APP_URL_REQ+'/stripe/paymentIntent',{
          method:'POST',
          headers:{
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.amount)
      })
      .then((response)=>{
        
          return response.json()
      })
      .then((json)=>{
          console.log(json);
          let clientSecret = json.id;
          let elements= stripe.elements();
          var style = {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                color: "#32325d"
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        };

        var card = elements.create("card", { style: style });
        // Stripe injects an iframe into the DOM
        card.mount("#card-element");
        card.on("change", function (event) {
            // Disable the Pay button if there are no card details in the Element
            document.querySelector("button").disabled = event.empty;
            document.querySelector("#card-error").textContent = event.error ? event.error.message : "";
        });

        var form = document.getElementById("payment-form");
        form.addEventListener("submit", function(event) {
        event.preventDefault();
        // Disable payment button
        document.querySelector("button").disabled = true;
        // Spinner loading
        this.loading = true;
        // Complete payment when the submit button is clicked
            stripe
                .confirmCardPayment(clientSecret, {
                payment_method: {
                    card: card
                }
                })
                .then(function(result) {
                if (result.error) {
                    // Show error to your customer
                    showError(result.error.message);
                    // le panier reste rempli mais orientation vers la page d'erreur
                    router.push({name: 'PaymentResult', params: {result: 'Erreur de paiement: '+result.error.message}})
                } else {
                    // The payment succeeded!
                    orderComplete(result.paymentIntent.id);
                    // transmettre les éléments pour faire la facture (adresse, produits, montants au serveur
                    let d = new Date();
                    let Y = String(d.getFullYear());
                    let M = String(d.getMonth()+1);
                    let D = String(d.getDay());
                    let h = String(d.getHours());
                    let m = String(d.getMinutes());
                    let s = String(d.getSeconds());
                    let number = Y+M+D+h+m+s;
                    let tab = [
                        invoiceTab.idUser= localStorage.getItem('id'),
                        invoiceTab.billingNumber= number,
                        invoiceTab.userInvoice= store.state.user,
                        invoiceTab.productCart= store.state.cart,
                        invoiceTab.amountCart= store.state.amountCart,
                        invoiceTab.transportCost= store.state.amountTransport,
                        invoiceTab.urlInvoice= '/invoice'+number+'.pdf'
                    ];
                    fetch(process.env.VUE_APP_URL_REQ+'/api/invoices',{
                      method:'POST',
                      headers:{
                        'Content-Type' : 'application/json',
                      },
                      mode: 'no-cors',
                      body: JSON.stringify(tab)
                    })
                    .then(response=>{
                        return response.json()
                    })
                    .then(json=>{
                        console.log(json)
                    })
                    .catch(error=>{
                        console.log(error)
                    })
                    // vider le panier 
                    store.dispatch('cleanCart');
                    //vider le sessionStorage
                    sessionStorage.removeItem('Panier')
                    // redirection vers la page success
                    router.push({name: 'PaymentResult', params: {result: "Votre paiement a été validé avec la référence suivante: "+result.paymentIntent.id}})
                }
                });
            });
         
      })
      .catch((error)=>{
          console.log('Error: '+error)
      })
      /* ------- UI helpers ------- */

    // Shows a success message when the payment is complete
        var orderComplete = function(paymentIntentId) {
        loading(false);
        document
            .querySelector(".result-message a")
            .setAttribute(
            "href",
            "https://dashboard.stripe.com/test/payments/" + paymentIntentId
            );
        document.querySelector(".result-message").classList.remove("hidden");
        document.querySelector("button").disabled = true;
        };

    // Show the customer the error from Stripe if their card fails to charge
        var showError = function(errorMsgText) {
        loading(false);
        var errorMsg = document.querySelector("#card-error");
        errorMsg.textContent = errorMsgText;
        setTimeout(function() {
            errorMsg.textContent = "";
        }, 4000);
    };
    // Show a spinner on payment submission
    var loading = function(isLoading) {
    if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
    } else {
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
    }
  };
  },
};
</script>
<style lang="scss" scoped>
    @import '../../styles/global.scss';
</style>