<template>
    <div>  
        <div v-if="userAddress.adress">
            <p><strong>Adresse:</strong> {{ userAddress.adress }}</p>
            <p><strong>Ville:</strong> {{ userAddress.city }}</p>
            <p><strong>Code postal:</strong> {{ userAddress.zipCode }}</p>
            <p><strong>Pays:</strong> {{ userAddress.country }}</p>
        </div>
        <form-adress v-else />
    </div>
</template>
<script>
import FormAdress from '@/components/elements/FormAdress.vue';
import { mapState } from 'vuex';
export default {
    name:"ShowAdress",
    components:{
        FormAdress
    },
    computed:{
        ...mapState(['userAddress'])
    },
      async created(){
      this.$store.dispatch('fetchUserAddress', localStorage.getItem('id'))
    },
}
</script>