<template>
    <div class="loading">
        <img src="../assets/loading.gif" alt="loading">
    </div>
</template>
<script>
export default {
    Name:'Loading'
}
</script>
<style lang="scss" scoped>
    .loading{
        margin: 20px;
    }
    img{
        width: 20%;
    }
</style>