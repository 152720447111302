<template>
    <div>
        <h3>Adresse de {{ formType }}</h3>
        <div v-if="openForm">
        <form @submit.prevent="handleSubmit(formType)">
            <div class="form-row">
                <div class="form-group">
                    <label for="inputPhone">Téléphone</label>
                    <input type="text" class="form-control" id="inputPhone" placeholder="Télephone" v-model="adressUser.phone">
                </div>
                <div class="form-group">
                    <label for="Nom">Nom</label>
                    <input type="text" class="form-control" id="Nom" placeholder="Nom" v-model="adressUser.lastName">
                </div>
                <div class="form-group">
                    <label for="prenom">Prénom</label>
                    <input type="text" class="form-control" id="prenom" placeholder="Prénom" v-model="adressUser.firstName">
                </div>
            </div>
            <div class="form-group">
                <label for="inputAddress">Adresse</label>
                <input type="text" class="form-control" id="inputAddress" placeholder="Votre adresse de livraison" v-model="adressUser.adress">
            </div>
           
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="inputCity">Ville</label>
                    <input type="text" class="form-control" id="inputCity" v-model="adressUser.city">
                </div>
                    <div class="form-group col-md-4">
                    <label for="inputState">Pays</label>
                    <select id="inputState" class="form-control" v-model="adressUser.country">
                        <option selected>France</option>
                        <option>Belgique</option>
                        <option>Suisse</option>
                        <option>Luxembourg</option>
                    </select>
                </div>
                <div class="form-group col-md-2">
                    <label for="inputZip">Code Postal</label>
                    <input type="text" class="form-control" id="inputZip" v-model="adressUser.zipCode">
                </div>
            </div>
            <div>
                <button type="submit" class="btn btn-outline-primary">Valider</button>
            </div>
        </form>
        </div>   
    </div>
</template>
<script>
export default {
    Name:"FormAdress",
    data(){
        return{
            openForm: true,
            adressUser:{
                id: localStorage.getItem('id'),
                email: "",
                phone: "",
                firstName: "",
                lastName: "",
                adress: "",
                city: "",
                zipCode: "",
                country: ""
            }
            
        }
    },
    props:{
        formType: String
    },
    created(){
        fetch(process.env.VUE_APP_URL_REQ+'/api/users/'+localStorage.getItem('id'),{
            method: 'GET',
            headers:{
                'Authorization' : 'Bearer '+localStorage.getItem('tok')
            }
        })
        .then(response=>{
            return response.json()
        })
        .then(json => {
            console.log(json)
        })
    },
    methods: {
        handleSubmit(formType){
            console.log(formType)
            fetch(process.env.VUE_APP_URL_REQ+'/api/registerAdressUser/'+localStorage.getItem('id'),{
                method:'POST',
                headers:{
                    'Content-Type' : 'application/json',
                },
                body: JSON.stringify(this.adressUser)
            })
            this.openForm = false
        }
    },
}
</script>